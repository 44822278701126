import MainHeader from '../../components/common/header/main-header'
import { Link, useParams } from 'react-router-dom'
import ProductDetailsTab from '../../components/common/products/Product-details-tab';
import React, { useCallback, useEffect, useState } from 'react';
import WeeklyDealsCard from '../../components/common/ui/Cards/weekly-deals-card';
import Footer from '../../components/common/footer/footer';
import ProductGallery from './product-details-gallery';
import ProductSkeleton from '../../components/shared/skeleton/ProductSkeleton';
import useUtils from '../../hooks/useUtils';
import { useGetcjProductDetailsQuery, useGetcjProductsQuery } from '../../features/api/cj-api';
import { getShapedProduct } from '../../utils/utils';

export default function ProductDetailPage() {
    const { id } = useParams<{ id: string }>();

    const [selectedVariant, setSelectedVariant] = useState<any>(null);
    const { handleWishlist, checkExistInWishlist } = useUtils();

    const { isLoading: loader, data: productDetails } = useGetcjProductDetailsQuery(id);

    const shapedProduct = useCallback(() => getShapedProduct(productDetails), [productDetails])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    return (
        <>
            <MainHeader />
            {/* ====== breadcrumbs ======= */}
            <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-5'>
                <div className='flex items-center gap-x-4 pt-8'>
                    <Link to={`/`} className='underline'>Home</Link>
                    <p className='text-base'>/</p>
                    <Link to={`/shop?search=bag`} className='underline'>Shops</Link>
                    <p className='text-base'>/</p>
                    <span className='underline text-gray-400'>{shapedProduct()?.title}</span>
                </div>
                {/* ======= cards ======= */}

                {!productDetails || loader ? (
                    <div className="mt-10 w-full p-10">
                        <div className="grid grid-cols-6 gap-5">
                            <div className="col-span-6 lg:col-span-4 gap-y-3">
                                <ProductSkeleton />
                                <ProductSkeleton />
                            </div>
                            <div className="col-span-6 lg:col-span-2">
                                <ProductSkeleton />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {!loader && <ProductGallery _id={id} images={productDetails?.productImageSet} setSelectedVariant={setSelectedVariant} selectedVariant={selectedVariant} />}
                    </>
                )}
                {!loader && <ProductDetailsTab product={productDetails} />}
                <h3 className='font-bold 2xl:text-2xl xl:text-2xl md:text-2xl lg:text-2xl text-xl mt-8 mb-6'>
                    Related Products
                </h3>
                <ProductRecommendations id={id} />
            </div>

            <Footer />
        </>
    )
}
// ======= Product Recommendations =================================
const ProductRecommendations = ({ id }: { id: string | undefined }) => {
    const { isLoading, data, isFetching } = useGetcjProductsQuery({
        limit: 10,
        sortKey: "BEST_SELLING"
    });

    return (
        <div className='grid 2xl:grid-cols-5 xl:grid-cols-5 grid-cols-1 gap-6 mb-12'>
            {(isLoading || isFetching) ?
                Array(5).fill(0).map((_, i) => (
                    <div key={i} className='w-full bg-white p-3 rounded-2xl'>
                        <div className='animate-pulse flex flex-col gap-6 w-full'>
                            <div className='w-full h-[150px] bg-gray-200 rounded-xl' />
                            <div className='w-full h-4 bg-gray-200 rounded-xl' />
                            <div className='w-full h-4 bg-gray-200 rounded-xl' />
                        </div>
                    </div>
                ))
                : data?.products?.
                    filter((item: any) => item?.image !== null)?.
                    map((item: any, index: number) => (
                        <WeeklyDealsCard
                            item={item}
                            key={item.id || item.pid}
                            id={item.id || item.pid}
                        />
                    ))}
        </div>
    )
}

