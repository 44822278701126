import React from 'react'

const ProductSkeleton = () => {
    return (
        <div className='w-full h-[300px] bg-[#F3F5F6] rounded-lg'></div>
    )
}

export const ProductsSkeleton = () => {
    return (
        <div className='grid lg:grid-cols-5 gap-5'>
            {
                [...Array(15)].map((_, index) => (
                    <div key={index} className=''>
                        <div className='w-full h-[200px] bg-gray-100 animate-pulse'></div>
                        <div className='w-full h-[20px] bg-gray-100 animate-pulse'></div>
                        <div className='w-full h-[20px] bg-gray-100 animate-pulse'></div>
                    </div>
                ))
            }
        </div>
    )
}

export default ProductSkeleton