import commonApi from "../../redux/common-api";

const CjApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getcjProducts: builder.query({
            query: ({
                pageNum = 1,
                pageSize = 10,
                productType = "SUPPLIER_PRODUCT",
                minPrice = 0,
                maxPrice = 100,
                searchType = 0,
                sort = "price",
                orderBy = "asc",
                categoryId = "",
                caching = true
            }) => `product/cj/products?pageNum=${pageNum}&pageSize=${pageSize}&productType=${productType}&minPrice=${minPrice}&maxPrice=${maxPrice}&searchType=${searchType}&sort=${sort}&orderBy=${orderBy}&caching=${caching}&categoryId=${categoryId}`,
            providesTags: ["cj-products"]
        }),
        getcjProductDetails: builder.query({
            query: (id) => `product/cj/products/${id}`,
            providesTags: ["cj-products"]
        }),
        getcjProductCategories: builder.query({
            query: () => `product/cj/products/categories/list`,
            providesTags: ["cj-products"]
        }),
        getcjProductReviews: builder.query({
            query: (id) => `product/cj/products/reviews/${id}`,
            providesTags: ["cj-products"]
        }),
    })
})


export const { useGetcjProductsQuery, useGetcjProductDetailsQuery,
    useGetcjProductCategoriesQuery, useGetcjProductReviewsQuery
} = CjApi;