import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CartButton, LoginRegisterButton, RefreshButton, WishlistButton } from '../ui/button/cart-button';
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProfileQuery } from '../../../features/slices/api-slices';
import { logout, setToken, setUser } from '../../../features/slices/auth-slice';
import { setCartLength } from '../../../features/slices/products-slice';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { getDropdownLinks } from '../../../utils/links';
import { LiaUserSolid } from 'react-icons/lia';
import MobileMenu from '../../shared/navbar/mobile-navbar';
import useDeviceDetect from '../../../hooks/device-detect';
import { SearchCategory, CurrencyDropdown } from '../..';
import commonApi from '../../../redux/common-api';
import LogoutConfirmModal from '../ui/modal/logout-confirmation-modal';


export default function MainHeader() {

  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const currencies = [
    { code: 'USD', name: 'USD', symbol: '$' },
    { code: 'EUR', name: 'EURO', symbol: '€' },
    { code: 'INR', name: 'INR', symbol: '₹' },
    // Add more currencies as needed
  ];
  const device = useDeviceDetect();
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
  const dispatch = useDispatch();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const location = useLocation();
  const { token, user } = useSelector((state: RootState) => state.auth);
  const { isLoading: loadingUser, data: userData } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const [cartItems] = useLocalStorage("my_cart", []);
  const userDropdownLinks = getDropdownLinks(user?.role);

  useEffect(() => {
    dispatch(setCartLength(cartItems?.length));
  }, [cartItems, dispatch]);


  useEffect(() => {
    if (!loadingUser && userData) {
      dispatch(setToken(localStorage.getItem("token") || ""));
      dispatch(setUser(userData));
    }
  }, [loadingUser, userData, dispatch]);

  const handleAccount = () => {
    if (!token) {
      window.location.assign("/auth/signin");
      return;
    }
    if (user?.role === 0) {
      window.location.assign("/profile");
      return;
    }
    if (user?.role === 1) {
      window.location.assign("/admin/dashboard");
    }
    if (user?.role === 2) {
      window.location.assign("/vendor/dashboard");
    }
  };

  const hiddenPaths = ["auth", "admin", "vendor",];
  const isNavBarHidden = hiddenPaths.some(path => location.pathname.includes(path));

  if (isNavBarHidden) {
    return null;
  }

  const handleRefresh = () => {
    dispatch(commonApi.util.invalidateTags([
      'Auth', 'Product', 'Order', 'shopify', 'product', 'magic-club', 
      'ebook', 'wallet', 'e-club', 'coupon', 'ad', 'vendor-business', 
      'admincustomers', 'pool'
    ]))
    dispatch(commonApi.util.invalidateTags(["shopify"]))
    console.log("refreshing");

  }

  return (
    <>
      {device === 'mobile' && <MobileNavbarMenu />}
      {device === 'tablet' && <MobileNavbarMenu />}
      {device === 'desktop' && <>
        <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-12 px-5 py-3 bg-white'>
          <nav className='flex justify-between gap-6 items-center w-full'>
            <Link to={'/'}>
              <img src="/logo.gif" className='w-36' alt="" />
            </Link>
            <div className='w-2/5 2xl:block xl:block hidden'>
              <SearchCategory />
            </div>
            <div className='flex gap-3 cursor-pointer items-center'>

              <div className='flex items-center gap-2'>
                <CurrencyDropdown
                  currencies={currencies}
                  selectedCurrency={selectedCurrency}
                  onChange={setSelectedCurrency}
                />
              </div>
              <div className='w-[1px] h-6 bg-black/30'></div>
              <div className='flex cursor-pointer items-center gap-2'>
                <img src="/assets/icons/others/eng.svg" alt="" />
                <p className='font-thin text-sm'>Eng</p>
              </div>
            </div>
            {/* ===== cart, wishlist, login ==== */}
            <div className='flex items-center gap-3'>
              {(user?.role === 0) && (
                <RefreshButton onClick={handleRefresh} />
              )}
              {token ? (
                <>
                  {(user?.role === 0 || !user) && (
                    <Link to={`/user-panel/wishlist`}>
                      <WishlistButton />
                    </Link>
                  )}
                </>
              ) : (
                <Link to={`/wishlist`}>
                  <WishlistButton />
                </Link>
              )}
              <div className='relative'>
                {token ? (
                  <div className='flex items-center cursor-pointer select-none' onClick={() => setOpenProfileMenu(!openProfileMenu)}>
                    <div className='size-10 flex mr-3 justify-center items-center bg-gray-100 rounded-full text-sm'>
                      <LiaUserSolid className='text-xl' />
                    </div>
                    <span>{user?.name?.split(" ")[0]}</span>
                    <MdOutlineKeyboardArrowDown size={25} />
                  </div>
                ) : (
                  <>
                    <LoginRegisterButton />
                  </>
                )}
                {openProfileMenu && (
                  <div id="dropdown" className="absolute top-10 right-0 z-[100000] bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                    <ul className="py-2 text-sm text-gray-700" onMouseLeave={() => setOpenProfileMenu(false)}>
                      {userDropdownLinks.map((link, index) => (
                        <li key={index}>
                          {link.path?.includes("logout") ? (
                            <button
                              onClick={() => {
                                setShowLogoutModal(true)
                              }}
                              className="block px-4 py-2 hover:bg-gray-100 w-full"
                            >
                              {link.title}
                            </button>
                          ) : (
                            <Link to={link.path} className="block px-4 py-2 hover:bg-gray-100">{link.title}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {
                token ? (
                  <>
                    {(user?.role === 0 || !user) && (
                      <Link to={`/user-panel/my-cart`}>
                        <CartButton />
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    <Link to={`/cart`}>
                      <CartButton />
                    </Link>
                  </>
                )
              }

            </div>
          </nav>
        </div>

        {/* =========== main links import here ======== */}
        <div className='w-full bg-black 2xl:py-4 xl:py-4 py-3'>
          <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-3'>
            <ul className='flex justify-center items-center gap-x-10'>
              <li className='text-white 2xl:text-sm xl:text-sm text-xs font-normal'>
                <Link to={`/`}>Home</Link>
              </li>
              <li className='text-white 2xl:text-sm xl:text-sm text-xs font-normal'>
                <Link to={`/shop`}>Shop</Link>
              </li>
              <li className='text-white 2xl:text-sm xl:text-sm text-xs font-normal'>
                <Link to={`/buy-ebooks`}>E Discount Coupon Book</Link>
              </li>
              <li className='text-white 2xl:text-sm xl:text-sm text-xs font-normal'>
                <Link to={`/magic-club`}>Magic Clubs</Link>
              </li>
              <li className='text-white 2xl:text-sm xl:text-sm text-xs font-normal'>
                <Link to={`/magic-pools`}>Magic Pools</Link>
              </li>
              <li className='text-white 2xl:text-sm xl:text-sm text-xs font-normal'>
                <Link to={`/cart`}>Cart</Link>
              </li>
              <li className='text-white 2xl:text-sm xl:text-sm text-xs font-normal'>
                <Link to={`/wishlist`}>Wishlist</Link>
              </li>
            </ul>
          </div>
        </div>
      </>}
      {
        showLogoutModal && <LogoutConfirmModal open={showLogoutModal} setOpen={setShowLogoutModal} />
      }
      {mobileMenu && <MobileMenu setMobileMenu={setMobileMenu} handleAccount={handleAccount} />}
    </>
  )
}


// ======= Responsive navbar ======
export const MobileNavbarMenu = () => {
  const dispatch = useDispatch();
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const location = useLocation();
  const { token, user } = useSelector((state: RootState) => state.auth);
  const { isLoading: loadingUser, data: userData } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });



  const [cartItems] = useLocalStorage("my_cart", []);
  const userDropdownLinks = getDropdownLinks(user?.role);
  useEffect(() => {
    dispatch(setCartLength(cartItems?.length));
  }, [cartItems, dispatch]);


  useEffect(() => {
    if (!loadingUser && userData) {
      dispatch(setToken(localStorage.getItem("token") || ""));
      dispatch(setUser(userData));
    }
  }, [loadingUser, userData, dispatch]);

  const hiddenPaths = ["auth", "admin", "vendor"];
  const isNavBarHidden = hiddenPaths.some(path => location.pathname.includes(path));

  if (isNavBarHidden) {
    return null;
  }

  const handleRefresh = () => {
    console.log("refreshing");

    commonApi.util.invalidateTags(["wallet"])
    commonApi.util.invalidateTags(["ad"])
  }

  return (
    <>
      <header className='px-3 pt-3'>
        <nav className='flex items-center justify-between'>
          <Link to={'/'}>
            <img src="/logo.gif" className='2xl:w-28 xl:w-28 w-48 cursor-pointer' alt="" />
          </Link>
          {/* ===== cart, wishlist, login ==== */}
          <div className='flex flex-col gap-4 items-end'>
            <div className='flex items-center gap-3'>
              <div className='relative'>
                {token ? (
                  <div className='flex items-center cursor-pointer select-none' onClick={() => setOpenProfileMenu(!openProfileMenu)}>
                    <div className='size-10 flex mr-3 justify-center items-center bg-gray-100 rounded-full text-sm'>
                      <LiaUserSolid className='text-xl' />
                    </div>
                    <span>{user?.name?.split(" ")[0]}</span>
                    <MdOutlineKeyboardArrowDown size={25} />
                  </div>
                ) : (
                  <>
                    <LoginRegisterButton />
                  </>
                )}
                {openProfileMenu && (
                  <div id="dropdown" className="absolute top-10 right-0 z-[100000] bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                    <ul className="py-2 text-sm text-gray-700" onMouseLeave={() => setOpenProfileMenu(false)}>
                      {userDropdownLinks.map((link, index) => (
                        <li key={index}>
                          {link.path?.includes("logout") ? (
                            <button
                              onClick={() => {
                                setOpenProfileMenu(false)
                                setShowLogoutModal(true)
                              }}
                              className="block px-4 py-2 hover:bg-gray-100 w-full"
                            >
                              {link.title}
                            </button>
                          ) : (
                            <Link to={link.path} className="block px-4 py-2 hover:bg-gray-100">{link.title}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {token ? (
                <>
                  {(user?.role === 0 || !user) && (
                    <Link to={`/user-panel/my-cart`}>
                      <CartButton />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link to={`/cart`}>
                    <CartButton />
                  </Link>
                </>
              )
              }
            </div>
            <div className='gap-4 justify-end hidden lg:flex'>
              {(user?.role === 0 || !user) && (
                <RefreshButton onClick={handleRefresh} />
              )}
              {token ? (
                <>
                  {(user?.role === 0 || !user) && (
                    <Link to={`/user-panel/wishlist`}>
                      <WishlistButton />
                    </Link>
                  )}
                </>
              ) : (
                <Link to={`/wishlist`}>
                  <WishlistButton />
                </Link>
              )}
            </div>
          </div>
        </nav>
        <div className='w-full pt-4'>
          <SearchCategory />
        </div>

      </header>
      {/* =========== main links import here ======== */}
      <div className='w-full bg-black py-5 mt-5 group'>
        <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-3'>
          <ul className='flex items-center gap-x-4 overflow-x-auto justify-center'>
            <li className='text-white 2xl:text-sm xl:text-sm flex-none text-xs font-normal'>
              <Link to={`/`}>Home</Link>
            </li>
            <li className='text-white 2xl:text-sm xl:text-sm flex-none text-xs font-normal'>
              <Link to={`/shop`}>Shop</Link>
            </li>
            <li className='text-white 2xl:text-sm xl:text-sm flex-none text-xs font-normal'>
              <Link to={`/buy-ebooks`}>Buy E Book</Link>
            </li>
            <li className='text-white 2xl:text-sm xl:text-sm flex-none text-xs font-normal'>
              <Link to={`/magic-club`}>Join Magic Clubs</Link>
            </li>
            <li className='text-white 2xl:text-sm xl:text-sm flex-none text-xs font-normal'>
              <Link to={`/cart`}>Cart</Link>
            </li>
            <li className='text-white 2xl:text-sm xl:text-sm flex-none text-xs font-normal'>
              <Link to={`/wishlist`}>Wishlist</Link>
            </li>
          </ul>

        </div>
      </div>
      {
        showLogoutModal && <LogoutConfirmModal open={showLogoutModal} setOpen={setShowLogoutModal} />
      }
    </>
  )
}