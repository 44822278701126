import { Link } from 'react-router-dom'
import WeeklyDealsCard from './ui/Cards/weekly-deals-card'
import { useStorefrontProductsQuery } from '../../features/api/products-api'
import ProductSkeleton from '../shared/skeleton/ProductSkeleton'
import CountdownTimer from './count-down-timer/count-down-timer'
import { Swiper, SwiperSlide } from 'swiper/react'
import useDeviceDetect from '../../hooks/device-detect'
import 'swiper/css';
import { Autoplay } from 'swiper/modules'
import { useGetcjProductsQuery } from '../../features/api/cj-api'

const WeeklyDealsSection = () => {
    const device = useDeviceDetect();
    // const currency = useSelector((state: AppState) => state.auth.currency)
    const { isLoading, data } = useGetcjProductsQuery({
        limit: 10,
        sortKey: "BEST_SELLING",
        searchType: 2,
        caching: false
    });

    return (
        <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-3'>
            <div className='w-ful 2xl:p-6 xl:p-6 p-4 bg-gray-100 rounded-xl 2xl:mt-8 xl:mt-8 md:mt-8 lg:mt-8 mt-3'>
                <div className='flex 2xl:flex-row xl:flex-row flex-col justify-between 2xl:items-center xl:items-center items-start gap-5 pb-8'>
                    <h3 className='text-xl font-medium'>
                        Best Weekly Deals
                    </h3>
                    <div className='flex 2xl:justify-end xl:justify-end justify-between 2xl:w-fit xl:w-fit w-full items-center gap-4'>
                        <CountdownTimer />
                        <Link to={`/shop`} className='2xl:text-sm xl:text-sm text-xs text-gray-500 border-b border-dashed border-gray-500'>See All Products</Link>
                    </div>
                </div>
                <div className='overflow-x-hidden'>
                    {isLoading ?
                        <div className='grid 2xl:grid-cols-5 xl:grid-cols-5 md:grid-cols-4 lg:grid-cols-4 grid-cols-2 gap-3'>
                            {
                                Array(6).fill(0).map((_, i) => (
                                    <ProductSkeleton key={i} />
                                ))
                            }
                        </div>
                        : (
                            <>
                                <Swiper spaceBetween={20}
                                    slidesPerView={device === "mobile" ? 1 : 5}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false
                                    }}
                                    modules={[Autoplay]}
                                    speed={3000}
                                    loop={true}>
                                    {data?.products?.map((item: any, index: number) => (
                                        <SwiperSlide key={index}>
                                            <WeeklyDealsCard
                                                item={item}
                                                id={item?.id || item?.pid}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                        )}
                </div>
            </div>
        </div>
    )
}

export default WeeklyDealsSection