import { useEffect, useState } from 'react';
import { useToast } from '../components/shared/ToastContext';


export const useOnlineStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const { showToast } = useToast();

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            showToast("Internet connection restored!", "success", "Internet connection is restored. You are online now.");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        };

        const handleOffline = () => {
            setIsOnline(false);
            showToast("Internet connection lost!", "error", "Internet connection is lost. You are offline now. Please check your internet connection.");
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return isOnline;
};
