import React from 'react'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import WeeklyDealsCard from './weekly-deals-card';
import { useStorefrontProductsQuery } from '../../../../features/api/products-api';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules'
import useDeviceDetect from '../../../../hooks/device-detect';
import { useGetcjProductsQuery } from '../../../../features/api/cj-api';
export default function TopDealsCard() {
    const [currentSlide, setCurrentSlide] = React.useState(1);
    const { isLoading, data, isFetching } = useGetcjProductsQuery({
        limit: currentSlide * 10,
        sortKey: "PRICE",
    });
    const device = useDeviceDetect();

    return (
        <>
            <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl-px-16 px-5 mt-8'>
                <div className='w-full p-4 bg-gray-100 rounded-xl'>
                    <div className='flex justify-between items-center pb-6'>
                        <h1 className='2xl:text-xl xl:text-xl text-base font-medium'>
                            Top Deals
                        </h1>
                        <div className='flex gap-2 bg-primary px-3 py-1.5 w-16 rounded-full justify-between items-center'>
                            <button type="button" className='border-nonr outline-none bg-transparent'
                                onClick={() => {
                                    if (currentSlide <= 0) return;
                                    setCurrentSlide(currentSlide - 1);
                                }}
                            >
                                <MdOutlineKeyboardArrowLeft />
                            </button>
                            <button type="button" className='border-nonr outline-none bg-transparent'
                                onClick={() => {
                                    if (currentSlide >= 5) return;
                                    setCurrentSlide(currentSlide + 1);
                                }}
                            >
                                <MdOutlineKeyboardArrowRight />
                            </button>
                        </div>
                    </div>
                    <div className='overflow-x-hidden'>
                        {(isLoading || isFetching) ?
                            Array(5).fill(0).map((_, i) => (
                                <div key={i} className='w-full bg-white p-3 rounded-2xl'>
                                    <div className='animate-pulse flex flex-col gap-6 w-full'>
                                        <div className='w-full h-[150px] bg-gray-200 rounded-xl' />
                                        <div className='w-full h-4 bg-gray-200 rounded-xl' />
                                        <div className='w-full h-4 bg-gray-200 rounded-xl' />
                                    </div>
                                </div>
                            ))
                            : (
                                <>
                                    <Swiper spaceBetween={20}
                                        slidesPerView={device === "mobile" ? 1 : 5}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false
                                        }}
                                        modules={[Autoplay]}
                                        speed={1000}
                                        loop={true}>
                                        {data?.products?.slice((currentSlide - 1) * 5, currentSlide * 5 + 5)?.map((item: any, index: number) => (
                                            <SwiperSlide key={index}>
                                                <WeeklyDealsCard
                                                    item={item}
                                                    key={item._id || item.id || item.pid}
                                                    id={item.id || item.pid}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}
