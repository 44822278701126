import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TopSellingProductCard from './ui/Cards/top-selling-product-card'
import { useStorefrontProductsQuery } from '../../features/api/products-api'
import { useGetcjProductsQuery } from '../../features/api/cj-api'

const ProductsByTypesCatalog = () => {
    const productsTypes = [
        {
            title: 'Top Selling',
            sortKey: 'BEST_SELLING'
        },
        {
            title: 'New',
            sortKey: 'CREATED_AT'
        },
        {
            title: 'Top Rated',
            sortKey: 'BEST_SELLING'
        },
        {
            title: 'Lowest Price',
            sortKey: 'PRICE'
        }
    ]

    const [selectedType, setSelectedType] = useState(productsTypes[0])

    const { isLoading, data } = useGetcjProductsQuery({
        limit: 12,
        sortKey: selectedType.sortKey
    });
    
    return (
        <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-3'>
            <div className='w-ful p-6 bg-gray-100 rounded-xl mt-8'>
                <div className='flex justify-between items-center pb-8'>
                    <ul className='flex items-center 2xl:gap-6 xl:gap-6 2xl:overflow-x-hidden xl:overflow-x-hidden md:overflow-x-hidden lg:overflow-x-hidden overflow-x-auto gap-4'>
                        {
                            productsTypes.map((item, index) => (
                                <li key={index}>
                                    <button type='button'
                                        className={`pb-2 outline-none bg-transparent 2xl:text-base xl:text-base text-xs tracking-wide border-b-2 border-solid ${selectedType.title === item.title ? 'border-primary text-primary' : 'border-transparent'}`}
                                        onClick={() => setSelectedType(item)}
                                    >{item.title}</button>
                                </li>
                            ))
                        }
                    </ul>
                    <div className='2xl:flex xl:flex hidden items-center gap-4'>
                        <Link to={`/shop`} className='text-sm text-gray-500 border-b border-dashed border-gray-500'>View All</Link>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-3'>
                            {
                                Array(4).fill(0).map((item, i) => (
                                    <div key={i} className='w-full bg-white p-3 rounded-2xl'>
                                        <div className='animate-pulse flex flex-col gap-6 w-full'>
                                            <div className='w-full h-[150px] bg-gray-200 rounded-xl' />
                                            <div className='w-full h-4 bg-gray-200 rounded-xl' />
                                            <div className='w-full h-4 bg-gray-200 rounded-xl' />
                                        </div>
                                    </div>
                                ))
                            }
                        </div> : <TopSellingProductCard products={data?.products} />
                }

            </div>
        </div>
    )
}

export default ProductsByTypesCatalog

