import React, { createContext, useState, useContext, ReactNode } from 'react';

interface FilterState {
    category?: string;
    priceRange: string[];
    brands: string[];
    materialType: string[];
    rating: string[];
    minPrice?: number;
    maxPrice?: number;
}

interface ShopContextProps {
    filters: FilterState;
    applyFilter: (filterType: keyof FilterState, filterValue: string | number | undefined) => void;
}

const ShopContext = createContext<ShopContextProps | null>(null);

export const ShopProvider = ({ children }: { children: ReactNode }) => {
    const [filters, setFilters] = useState<FilterState>({
        priceRange: [],
        brands: [],
        materialType: [],
        rating: [],
    });

    console.log(filters);
    
    const applyFilter = (filterType: keyof FilterState, filterValue: string | number | undefined) => {
        setFilters(prevFilters => {
            if (filterType === "minPrice" || filterType === "maxPrice" || filterType === "category") {
                // Set numeric and category filters directly
                return {
                    ...prevFilters,
                    [filterType]: filterValue,
                };
            } else {
                // Toggle list-based filters (priceRange, brands, materialType, rating)
                return {
                    ...prevFilters,
                    [filterType]: prevFilters[filterType].includes(filterValue as string)
                        ? prevFilters[filterType].filter(value => value !== filterValue)
                        : [...prevFilters[filterType], filterValue as string],
                };
            }
        });
    };

    return (
        <ShopContext.Provider value={{ filters, applyFilter }}>
            {children}
        </ShopContext.Provider>
    );
};

export const useShopContext = () => {
    const context = useContext(ShopContext);
    if (!context) {
        throw new Error("useShopContext must be used within a ShopProvider");
    }
    return context;
};
