import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdAdd, IoMdStar } from "react-icons/io";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../components/shared/ToastContext";
import { setCartLength } from "../../features/slices/products-slice";
import useLocalStorage from "../../hooks/useLocalStorage";
import { formatPrice, getCurrencySymbol, getShapedProduct } from "../../utils/utils";
import ProductVariants from "./product-variants";
import { FiMinus } from "react-icons/fi";

// import ProductVariants from "./ProductVariants";

interface ProductInfoProps {
    product: any;
    brand: string;
    title: string;
    ratings: number;
    price: string;
    reviews: any[];
    quantity: number;
    stock: number;
    handleIncrement: () => void;
    handleDecrement: () => void;
    setSelectedVariant: any;
    selectedVariant: any;
    isLoading: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
    product,
    brand,
    title,
    price,
    isLoading,
    reviews,
    quantity,
    stock,
    handleIncrement,
    handleDecrement,
    setSelectedVariant
}) => {
    console.log(product);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [myVariant, setMyVariant] = useState<any>(null);
    const [cart, setCart] = useLocalStorage("my_cart", []);
    // const [addedToWishlist, setAddedToWishlist] = useState(myWishlist?.find((item) => item?.id === product?.id));
    const [addedToCart, setAddedToCart] = useState(cart?.find((item: any) => item.id === product?.id));
    const { currency, cartLength, token } = useSelector((state: RootState) => state.auth);
    const { showToast } = useToast();
    const shapedProduct = useCallback(() => getShapedProduct(product), [product])

    const handleAddToCart = (data: any) => {
        let variant = myVariant || product?.variants?.[0];
        let cart = JSON.parse(localStorage.getItem("my_cart") || "[]");
        if (!cart) {
            localStorage.setItem("my_cart", JSON.stringify([]));
            cart = [];
        }
        if (cart.find((item: any) => item?.id === data?.id)) {
            setAddedToCart(false);
            dispatch(setCartLength(cartLength - 1));
            setCart((prev: any[]) => prev.filter((item) => item?.id !== data?.id));
            return showToast("Your product was removed successfully", "success", "Product Removed");
        }



        dispatch(setCartLength(cartLength + 1));
        setAddedToCart(true);

        setCart([...cart, data]);
        showToast("Your cart has been added successfully!", "success", "Product Added")
    }

    const handleBuyNow = () => {
        if (!token) {
            return navigate("/auth/signin?redirect=" + window.location.pathname);
        }
        handleAddToCart(shapedProduct());
        setTimeout(() => {
            navigate("/checkout");
        }, 2000);
    };

    return (
        <div className="">
            <div className="flex flex-col gap-5 w-full max-h-min lg:p-5">
                <div className="flex flex-col gap-2">
                    <h4 className='text-black 2xl:text-2xl xl:text-2xl 2xl:w-2/3 xl:w-2/3 leading-tight font-medium'>
                        {title}
                    </h4>
                    <div className='flex justify-between items-center 2xl:w-2/3 xl:2/3 w-full'>
                        <h4 className='text-2xl font-bold tracking-wide'>
                            {getCurrencySymbol(currency) + formatPrice(price)}
                        </h4>
                        {product?.rating ? <div className='bg-primary px-3 py-1 w-fit rounded-full text-sm flex items-center gap-2'>
                            5.0
                            <IoMdStar />
                        </div> : <p className='text-xs text-gray-500'>No rating</p>}
                    </div>
                    <div className="w-full">
                        <ProductVariants product={product} setSelectedVariant={setSelectedVariant} handleSelectedVariant={setMyVariant} />
                    </div>
                    <div className='flex items-center gap-4 pt-7'>
                        <p>
                            Quantity:
                        </p>
                        <div className="relative">
                            <button
                                onClick={handleDecrement}
                                className="cursor-pointer font-extralight text-xs bg-gray-100 size-10 p-0 rounded-full flex justify-center items-center"
                            >
                                <FiMinus size={16} />
                            </button>
                        </div>
                        <input type='text' value={quantity ? quantity : 1} className="w-[100px] outline-none text-center h-11 px-3 bg-gray-100 rounded-lg border border-black/20" />
                        <button
                            onClick={handleIncrement}
                            className="cursor-pointer font-extralight text-xs bg-gray-100 size-10 p-0 rounded-full flex justify-center items-center"
                        >
                            <IoMdAdd size={16} />
                        </button>
                    </div>
                    <p className='mt-6 font-normal'>
                        Discount Coupon:
                    </p>
                    <p className='text-lg font-medium mt-3'>
                        Apply Coupon: Extra 15% off with code BAG15
                    </p>
                    <div className='flex items-center gap-6 pt-10 2xl:w-2/3 xl:2/3 w-full'>
                        <button type="button" onClick={handleBuyNow} className='w-full 2xl:py-4 xl:py-4 md:py-4 lg:py-4 py-2 bg-primary text-sm rounded-full'>
                            Buy Now
                        </button>
                        <button type="button" disabled={stock < 1}
                            onClick={() =>
                                handleAddToCart(shapedProduct())
                            } className='w-full 2xl:py-4 xl:py-4 md:py-4 lg:py-4 py-2 border border-solid border-gray-700 text-sm rounded-full'>
                            {
                                addedToCart ? "Remove from Cart" : "Add to Cart"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductInfo;