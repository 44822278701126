import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Checkbox from "../ui/checkbox";
import { useShopContext } from "../../ShopContext";
import { useGetcjProductCategoriesQuery } from "../../../features/api/cj-api";

export const CategoryFilters: React.FC = () => {
    const { filters, applyFilter } = useShopContext();
    const { isLoading, data, isError } = useGetcjProductCategoriesQuery(undefined);

    // Local state for price range
    const [minPrice, setMinPrice] = useState<number | undefined>(filters.minPrice);
    const [maxPrice, setMaxPrice] = useState<number | undefined>(filters.maxPrice);

    const handleCheckboxChange = (filterType: keyof typeof filters, id: string) => {
        applyFilter(filterType, id);
    };

    const handlePriceRangeChange = () => {
        applyFilter("minPrice", minPrice);
        applyFilter("maxPrice", maxPrice);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error while loading filters</div>;
    }

    return (
        <div className='w-full 2xl:bg-gray-100 xl:bg-gray-100 bg-white rounded-2xl p-5 mt-6'>
            <h2 className="text-lg font-bold">Filter by Category</h2>
            {/* Render categories */}
            {data?.map((firstLevel: any) => (
                <FirstLevelSection
                    key={firstLevel.categoryFirstId}
                    title={firstLevel.categoryFirstName}
                    secondLevelList={firstLevel.categoryFirstList}
                    isChecked={(id: string) => filters.category === id}
                    handleCheckboxChange={(id) => handleCheckboxChange('category', id)}
                />
            ))}

            {/* Price Range Filter */}
            <div className='mt-6'>
                <h3 className='text-lg font-medium tracking-wider'>Price Range</h3>
                <div className='flex items-center gap-2 mt-2'>
                    <input
                        type="number"
                        placeholder="Min Price"
                        value={minPrice || ''}
                        onChange={(e) => setMinPrice(Number(e.target.value))}
                        className="rounded p-2 border border-gray-300 w-full"
                    />
                    <span>-</span>
                    <input
                        type="number"
                        placeholder="Max Price"
                        value={maxPrice || ''}
                        onChange={(e) => setMaxPrice(Number(e.target.value))}
                        className="rounded p-2 border border-gray-300 w-full"
                    />

                </div>
                <div className="flex justify-center mt-4 items-center gap-2">
                    <button
                        onClick={handlePriceRangeChange}
                        className='bg-primary rounded-lg flex justify-center items-center py-3 px-5'
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

interface FirstLevelSectionProps {
    title: string;
    secondLevelList: any[];
    isChecked: (id: string) => boolean;
    handleCheckboxChange: (id: string) => void;
}

const FirstLevelSection: React.FC<FirstLevelSectionProps> = ({ title, secondLevelList, isChecked, handleCheckboxChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen((prev) => !prev);

    return (
        <div className='w-full'>
            <div className='flex justify-between items-center cursor-pointer' onClick={toggleOpen}>
                <h3 className='text-lg font-medium'>{title}</h3>
                {isOpen ? <IoIosArrowUp className='text-lg' /> : <IoIosArrowDown className='text-lg' />}
            </div>
            {isOpen && (
                <div className="mt-4 pl-4">
                    {secondLevelList.map((secondLevel: any) => (
                        <SecondLevelSection
                            key={secondLevel.categorySecondId}
                            title={secondLevel.categorySecondName}
                            thirdLevelList={secondLevel.categorySecondList}
                            isChecked={isChecked}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

interface SecondLevelSectionProps {
    title: string;
    thirdLevelList: any[];
    isChecked: (id: string) => boolean;
    handleCheckboxChange: (id: string) => void;
}

const SecondLevelSection: React.FC<SecondLevelSectionProps> = ({ title, thirdLevelList, isChecked, handleCheckboxChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen((prev) => !prev);

    return (
        <div className='w-full'>
            <div className='flex justify-between items-center cursor-pointer pl-2' onClick={toggleOpen}>
                <h4 className='text-md font-medium'>{title}</h4>
                {isOpen ? <IoIosArrowUp className='text-md' /> : <IoIosArrowDown className='text-md' />}
            </div>
            {isOpen && (
                <div className="mt-4 pl-4">
                    {thirdLevelList.map((thirdLevel: any) => (
                        <Checkbox
                            key={thirdLevel.categoryId}
                            id={thirdLevel.categoryId}
                            label={thirdLevel.categoryName}
                            checked={isChecked(thirdLevel.categoryId)}
                            onChange={() => handleCheckboxChange(thirdLevel.categoryId)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CategoryFilters;
