import { IoIosStar } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import { TbShoppingBagPlus, TbShoppingBagMinus } from "react-icons/tb";
import { IoIosHeart } from "react-icons/io";
import { getCurrencySymbol, getShapedProduct } from "../../../../utils/utils";
import { AppState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import useUtils from "../../../../hooks/useUtils";
import { CartItem } from "../../../../types/type";
import { Link } from "react-router-dom";

type WeeklyDealsCardProps = {
    item?: any;
    id?: any;
}
export default function WeeklyDealsCard({
    item, id
}: WeeklyDealsCardProps) {
    const { handleCart, handleWishlist, checkExistInCart, checkExistInWishlist } = useUtils()
    const [addedToCart, setAddedToCart] = useState(checkExistInCart(item?.id))
    const [addedToWishlist, setAddedToWishlist] = useState(checkExistInWishlist(item?.id))
    const currency = useSelector((state: AppState) => state.auth.currency)

    const shapedProduct = useCallback(() => getShapedProduct(item), [item])

    return (
        <>
            <div className='w-full bg-white group cursor-pointer p-3 relative rounded-3xl 2xl:h-[347px] xl:h-[347px] md:h-[347px] lg:h-[347px] h-fit'>
                <div className='absolute right-6 top-6 flex flex-col gap-y-3 opacity-0 group-hover:opacity-100 transition-all ease-linear duration-500'>
                    <button type="button" className='size-8 bg-primary rounded-full flex justify-center items-center' onClick={() => {
                        handleWishlist(shapedProduct(), (res: boolean) => {
                            setAddedToWishlist(res)
                        })
                    }}>{addedToWishlist ? <IoIosHeart /> : <CiHeart />}
                    </button>
                    <button type="button" className={`size-8 bg-primary rounded-full flex justify-center items-center`} onClick={() => {
                        handleCart(shapedProduct(), (res: boolean) => {
                            setAddedToCart(res)
                        })
                    }}> {addedToCart ?
                        <TbShoppingBagMinus /> :
                        <TbShoppingBagPlus />
                        }
                    </button>
                </div>
                <Link to={`/product-details/${id}`} className="w-full 2xl:h-56 xl:h-56 md:h-56 lg:h-56 h-44 ">
                    <img src={shapedProduct()?.src} alt="" className="mb-4 w-full h-auto object-cover aspect-[16/9] lg:aspect-auto rounded-2xl max-h-56" />
                </Link>
                <Link to={`/product-details/${id}`} className='text-sm hover:text-primary capitalize'>{shapedProduct()?.title?.length > 50 ? shapedProduct()?.title?.slice(0, 50) + "..." : shapedProduct()?.title}</Link>
                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-4 pt-2'>
                    <div className='flex items-center gap-2'>
                        <p className='font-medium text-lg'>
                            {getCurrencySymbol(currency) + shapedProduct()?.price}
                        </p>
                        <span className='text-xs font-thin text-gray-500 line-through'>
                            {getCurrencySymbol(currency) + (Number(shapedProduct()?.price) * (item?.discount || 1.2)).toFixed(2)}
                        </span>
                    </div>
                    {item.rating ? <div className='bg-primary px-3 py-1 w-fit rounded-full text-sm flex items-center gap-2'>
                        5.0
                        <IoIosStar />
                    </div> : <p className='text-xs text-gray-500'>No rating</p>}
                </div>
            </div>
        </>
    )
}
