import React, { useState } from 'react'
import MainHeader from '../../components/common/header/main-header'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../../components/common/footer/footer';
import { useStorefrontProductsQuery } from '../../features/api/products-api';
import ProductSkeleton from '../../components/shared/skeleton/ProductSkeleton';
import { CategoryFilters } from '../../components/common/category-filter-sidebar/category-filter-sidebar';
import { ShopProvider, useShopContext } from '../../components/ShopContext';
import ProductCard from '../../components/common/products/shop/shop-product';
import { VscFilter } from "react-icons/vsc";
import { RiCloseLargeLine } from "react-icons/ri";
import { useGetcjProductsQuery } from '../../features/api/cj-api';

const CategoryDetailsPage: React.FC = () => {
    const [router] = useSearchParams();
    const [filterDrawer, setFilterDrawer] = useState(true);

    const handleFilterDrawer = () => {
        setFilterDrawer(!filterDrawer);
    }
    return (
        <ShopProvider>
            <MainHeader />
            <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-10 px-5 mb-20'>
                <div className='flex items-center gap-x-3 pt-8'>
                    <Link to={`/`} className='text-base font-medium underline'>Home</Link>
                    <p>/</p>
                    <Link to={`/shop`} className='text-base font-medium underline'>Shop</Link>
                    <p>/</p>
                    <Link to={`#`} className='text-base font-medium underline text-gray-400'>{router.get("search")}</Link>
                </div>
                {/* ========= Responsive category filters ======= */}
                <button type="button" onClick={handleFilterDrawer} className='2xl:hidden xl:hidden outline-none inline-flex items-center gap-x-3 border border-solid border-gray-200 rounded px-4 py-1.5 my-4'>
                    <VscFilter className='text-lg' />
                    <p className='text-sm font-medium'>Filters</p>
                </button>
                <div
                    className={`2xl:hidden xl:hidden fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 z-40 ${filterDrawer ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                    onClick={handleFilterDrawer}
                ></div>

                {/* Drawer */}
                <div
                    className={`2xl:hidden xl:hidden fixed top-0 left-0 w-72 h-full bg-white shadow-xl transition-transform duration-300 z-50 transform ${filterDrawer ? '-translate-x-0' : '-translate-x-full'
                        }`}
                >
                    <button type='button' onClick={handleFilterDrawer} className='absolute top-4 right-4 outline-none border-none size-5 flex justify-center items-center'>
                        <RiCloseLargeLine />
                    </button>
                    <div className='h-6'></div>
                    <CategoryFilters />
                </div>
                {/* ======== Category options filters ======== */}
                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col items-start gap-8'>

                    <div className='2xl:w-1/4 xl:w-1/4 md:w-1/4 lg:w-1/4 w-full 2xl:block xl:block hidden'>
                        {/* ========== category filters ======= */}
                        <CategoryFilters />
                    </div>
                    {/* ========= products here ========= */}
                    <ShopProducts />
                </div>
            </div>

            <Footer />
        </ShopProvider>
    )
}

const ShopProducts = () => {
    const [router] = useSearchParams();
    const { filters } = useShopContext();
    // Format filter parameters

    const categoryId = filters.category ? filters.category : undefined;
    const brands = filters.brands.length > 0 ? filters.brands.join(',') : undefined;
    const materialType = filters.materialType.length > 0 ? filters.materialType.join(',') : undefined;
    const rating = filters.rating.length > 0 ? filters.rating.join(',') : undefined;
    const search = router.get('search')?.replace(/%20/g, ' ') || '';

    const { isLoading, data } = useGetcjProductsQuery({
        pageNum: 1, // Default page number
        pageSize: 20,
        categoryId,
        minPrice: filters.minPrice,
        maxPrice: filters.maxPrice,
        sort: 'BEST_SELLING',
        searchType: 0, // Adjust based on requirements
        search,
        brands,
        materialType,
        rating,
        caching: false,
    });

    return (
        <div className='2xl:w-4/5 xl:w-4/5 w-full'>
            <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 gap-x-6 gap-y-8'>

                {data?.products && data?.products.length > 0 ? (
                    <>
                        {isLoading ?
                            Array(6).fill(0).map((_, i) => (
                                <ProductSkeleton key={i} />
                            ))
                            : data?.products?.map((item: any, index: number) => (
                                <ProductCard
                                    item={item}
                                    id={item.id}
                                />
                            ))}
                    </>
                ) : (
                    isLoading ? <div className='grid grid-cols-4'>
                        {
                            [1, 2, 3, 4].map((item, index) => {
                                return <div className='bg-gray-100 w-full min-h-[200px]' key={index}>
                                </div>
                            })
                        }
                    </div>
                        : <>
                            <p className='text-center text-gray-600 mt-6'>No products found.</p>
                        </>
                )}
            </div>
        </div>
    )
}


export default CategoryDetailsPage


