import React, { useEffect, useState } from "react";

interface Variant {
    option1: string; // Size
    option2: string | null; // Color
    vid: string;
    variantImage: string;
    // Other fields if needed
}

interface Product {
    variants: Variant[];
}

interface ProductVariantsProps {
    product: Product;
    setSelectedVariant: (variant: Variant | null) => void;
    handleSelectedVariant: (variant: Variant | null) => void;
}

const ProductVariants: React.FC<ProductVariantsProps> = ({
    product,
    setSelectedVariant,
    handleSelectedVariant,
}) => {

    const [selectedSize, setSelectedSize] = useState<string | null>(null);
    const [selectedColor, setSelectedColor] = useState<string | null>(null);

    useEffect(() => {
        if (selectedSize && selectedColor) {
            handleAddToCart();
        }
    }, [selectedSize, selectedColor]);

    const handleSizeSelect = (size: string) => {
        setSelectedSize(size);
        const firstVariantWithSize = product.variants.find(variant => variant.option1 === size);
        if (firstVariantWithSize) {
            setSelectedVariant(firstVariantWithSize);
        }
    };

    const handleColorSelect = (color: string) => {
        setSelectedColor(color);
        const variantWithSizeAndColor = product.variants.find(variant => variant.option1 === selectedSize && variant.option2 === color);
        if (variantWithSizeAndColor) {
            setSelectedVariant(variantWithSizeAndColor);
        }
    };

    const uniqueColors = [...new Set(product.variants.map(variant => variant.option2).filter(color => color !== null))];
    const uniqueSizes = [...new Set(product.variants.map(variant => variant.option1))];

    const filterVariants = () => {
        if (selectedSize && selectedColor) {
            return product.variants.filter(variant => variant.option1 === selectedSize && variant.option2 === selectedColor);
        }
        return [];
    };

    const handleAddToCart = () => {
        const filteredVariants = filterVariants();
        if (filteredVariants.length > 0) {
            const selectedVariant = filteredVariants[0];
            handleSelectedVariant(selectedVariant);
        } else {
            handleSelectedVariant(null);
        }
    };

    return (
        <div className="flex flex-col gap-4 my-10 mb-2 mt-3">
            <h4 className='text-xl font-medium tracking-wide mt-4'>
                {/* Color {selectedSize ? `(${selectedSize})` : ""} */}
            </h4>
            <div className="w-full flex flex-wrap gap-2">
                {product?.variants.length > 0 ? (
                    product?.variants?.map((variant, index) => (
                        <button
                            key={index}
                            onClick={() => handleSelectedVariant(variant)}
                            className={`rounded-full text-xs ${selectedSize === variant?.vid ? 'bg-yellow-400 text-white' : 'border border-gray-300'}`}
                        >
                            <img src={variant?.variantImage} alt={variant?.option1} className="size-10 object-cover" />
                        </button>
                    ))
                ) : (
                    <p>No sizes available</p>
                )}
            </div>
        </div>
    );
};

export default ProductVariants;
